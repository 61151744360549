<template>
  <v-container class="white d-flex flex-column align-center py-14 mt-3 mt-md-6">
    <v-row
      ><v-col class="font-weight-bold">
        <span v-if="!userData"> {{ $t('components.authorizationRequired.textOne') }} </span>
        <span v-else>{{ $t('components.authorizationRequired.textTwo') }}</span>
      </v-col></v-row
    >
    <div v-if="!userData">
      <v-row
        ><v-col
          ><p class="text-center">
            {{ $t('components.authorizationRequired.textThree') }}
          </p></v-col
        >
      </v-row>
      <v-row
        ><v-col
          ><primary-button :to="{ name: 'Login' }">{{ $t('helpers.logIn') }}</primary-button>
        </v-col>
        <v-col
          ><primary-button :to="{ name: 'Register' }">{{
            $t('components.authorizationRequired.registerCompany')
          }}</primary-button>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import PrimaryButton from '../components/buttons/PrimaryButton.vue';
import { userDataComputed } from '../store/helper';
export default {
  name: 'AuthorizationRequired',
  components: { PrimaryButton },
  computed: {
    ...userDataComputed,
  },
};
</script>
